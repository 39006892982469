import React from 'react'
import Products from './Products'
import NavBar from './NavBar';
import ContactsRemainingComp from './ContactsRemaining';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const BuyContacts = () => {
    const auth = getAuth();
    const navigate = useNavigate();
    auth.onAuthStateChanged((user) => {
        if (!user) {
            navigate('/login');
        }})
    return (
        <div>
            <NavBar pageMiddle='Settings' pathMiddle='/settings' pageFirst='Home' pathFirst='/' loggedIn={true}/>
            <h1 className='settings-header'>Buy More Credits</h1>
            <ContactsRemainingComp />
            <Products />
        </div>
    )
}

export default BuyContacts;