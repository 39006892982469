import {React, useState, useEffect} from 'react';
import { getAuth } from 'firebase/auth';
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';

const ContactsRemainingComp = (props) => {

    const [contactsRemaining, setContactsRemaining] = useState(0)

    const auth = getAuth()

    auth.onAuthStateChanged((user) => {
        const uid = user.uid;
        getDoc(doc(db, "users", uid)).then(docSnap => {
            setContactsRemaining(docSnap.data().contactsRemaining);
    })
    })

    if(props.settings){
        if(contactsRemaining >= 200){
            return (
                <div>
                <div className='remaining-contacts-high-settings'>
                    <h2>{contactsRemaining} credits remaining</h2>
                </div>
                </div>
            )
        }
        if(contactsRemaining < 200 && contactsRemaining >= 100){
            return (
                <div>
                <div className='remaining-contacts-medium-settings'>
                    <h2>{contactsRemaining} credits remaining</h2>
                </div>
                </div>
            )
        }
        if(contactsRemaining < 100){
            return (
                <div>
                <div className='remaining-contacts-low-settings'>
                    <h2>{contactsRemaining} credits remaining</h2>
                </div>
                </div>
            )
        }
    } else {
        if(contactsRemaining >= 200){
            return (
                <div>
                <div className='remaining-contacts-high'>
                    <h2>{contactsRemaining} credits remaining</h2>
                </div>
                </div>
            )
        }
        if(contactsRemaining < 200 && contactsRemaining >= 100){
            return (
                <div>
                <div className='remaining-contacts-medium'>
                    <h2>{contactsRemaining} credits remaining</h2>
                </div>
                </div>
            )
        }
        if(contactsRemaining < 100){
            return (
                <div>
                <div className='remaining-contacts-low'>
                    <h2>{contactsRemaining} credits remaining</h2>
                </div>
                </div>
            )
        }
    }
}

export default ContactsRemainingComp;