import React, {useState, useEffect} from 'react';
import NavBar from './NavBar';
import {getAuth, onAuthStateChanged} from 'firebase/auth';
import { db, fbFunctions } from '../firebase';
import { doc, getDoc, getDocs, collection, updateDoc } from "firebase/firestore";
import CustomButton from './Button';
import ContactsRemainingComp from './ContactsRemaining';
import {httpsCallable} from 'firebase/functions'
import { useNavigate } from 'react-router-dom';


const Settings = () => {

    const auth = getAuth();
    const user = auth.currentUser;
    const uid = user.uid;
    const navigate = useNavigate()

    const [disabled, setdisabled] = useState(true);
    const [confirm, setConfirm] = useState(false);
    const [saveButton, setSaveButton] = useState(false);
    const [confirmState, setConfirmState] = useState(false);
    const [state, setState] = useState("");
    const [contactsRemaining, setContactsRemaining] = useState("");
    const [sameState, setSameState] = useState(false);

    const getOptionSelect = (e) => {
            setState(e);
    }

    const getSubDetails = async () => {
        getDoc(doc(db, "users", uid)).then((docSnap) => {
            setContactsRemaining(docSnap.data().contactsRemaining)
        })
    }

    const saveState = () => {
        const auth = getAuth();
        const user = auth.currentUser;
        const uid = user.uid;
        if(state != 'Select'){
            if(state != ""){
            updateDoc(doc(db, "users", uid), {
                state: state
            })
            setConfirmState(true);
            setSameState(false);
        }
        else{
            setConfirmState(false);
            setSameState(true);
        }
    }
        else {
            setConfirmState(false);
            setSameState(true);
        }
    }

    auth.onAuthStateChanged((user) => {
        if (!user) {
            navigate('/login');
        }
    else {
        getSubDetails();
    }})
    return(
        <div>
            <NavBar pageMiddle='Buy Credits' pathMiddle='/buy-contacts' pageFirst='Home' pathFirst='/' loggedIn={true}/>
            <div>
                <h1 className='settings-header'>Settings</h1>
            </div>
            <div className='fec-input-container'>
                <div>
                    <label className='fec-input-label'>
                            Choose your State
                    </label>
                </div>
                <div>
                    <select className='state-select' onChange={e => getOptionSelect(e.target.value)} defaultValue={'Select'}>
                        <option value='Select'>Select...</option>
                        <option value="AL">Alabama</option>
                        <option value="AK">Alaska</option>
                        <option value="AZ">Arizona</option>
                        <option value="AR">Arkansas</option>
                        <option value="CA">California</option>
                        <option value="CO">Colorado</option>
                        <option value="CT">Connecticut</option>
                        <option value="DE">Delaware</option>
                        <option value="DC">District Of Columbia</option>
                        <option value="FL">Florida</option>
                        <option value="GA">Georgia</option>
                        <option value="HI">Hawaii</option>
                        <option value="ID">Idaho</option>
                        <option value="IL">Illinois</option>
                        <option value="IN">Indiana</option>
                        <option value="IA">Iowa</option>
                        <option value="KS">Kansas</option>
                        <option value="KY">Kentucky</option>
                        <option value="LA">Louisiana</option>
                        <option value="ME">Maine</option>
                        <option value="MD">Maryland</option>
                        <option value="MA">Massachusetts</option>
                        <option value="MI">Michigan</option>
                        <option value="MN">Minnesota</option>
                        <option value="MS">Mississippi</option>
                        <option value="MO">Missouri</option>
                        <option value="MT">Montana</option>
                        <option value="NE">Nebraska</option>
                        <option value="NV">Nevada</option>
                        <option value="NH">New Hampshire</option>
                        <option value="NJ">New Jersey</option>
                        <option value="NM">New Mexico</option>
                        <option value="NY">New York</option>
                        <option value="NC">North Carolina</option>
                        <option value="ND">North Dakota</option>
                        <option value="OH">Ohio</option>
                        <option value="OK">Oklahoma</option>
                        <option value="OR">Oregon</option>
                        <option value="PA">Pennsylvania</option>
                        <option value="RI">Rhode Island</option>
                        <option value="SC">South Carolina</option>
                        <option value="SD">South Dakota</option>
                        <option value="TN">Tennessee</option>
                        <option value="TX">Texas</option>
                        <option value="UT">Utah</option>
                        <option value="VT">Vermont</option>
                        <option value="VA">Virginia</option>
                        <option value="WA">Washington</option>
                        <option value="WV">West Virginia</option>
                        <option value="WI">Wisconsin</option>
                        <option value="WY">Wyoming</option>
                    </select>
                        <div className='fec-buttons'>
                            <CustomButton  buttonStyle='teal-button' buttonLabel='Save State' onClick={() => saveState()} />
                        </div>
                </div>
            <div>
                {confirmState && <p className='fec-success'>State Updated</p>}
                {sameState && <p className='same-state-selected'>No state Selected</p>}
            </div>
        </div>
        <div className='fec-input-container'>
            <ContactsRemainingComp contactsRemaining={contactsRemaining} settings={true} />
            <CustomButton buttonStyle='teal-button' buttonLabel='Buy Credits' onClick={() => navigate('/buy-contacts')} />
            <h3>Contacts that require a Phone Number and/or Email Address lookup cost two (2) credits.</h3>
        </div>
    </div>
    )
}

export default Settings;