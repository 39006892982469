import {Reac,useState} from 'react'
import {getAuth, onAuthStateChanged} from 'firebase/auth';
import { db } from '../firebase';
import { doc, getDoc, setDoc, updateDoc, addDoc, collection, onSnapshot } from "firebase/firestore";
import CustomButton from './Button';
import Products from './Products';
import NavBar from './NavBar';
import logo from './images/RaiseSmart AI.png'
import { useNavigate } from 'react-router-dom';

const Onboarding = () => {

    const navigate = useNavigate()

    const [confirmState, setConfirmState] = useState(false);
    const [state, setState] = useState("");
    const [loading, setLoading] = useState(false);

    const getOptionSelect = (e) => {
        setState(e);
    }

    const saveState = () => {
        const auth = getAuth();
        const user = auth.currentUser;
        const uid = user.uid;
        updateDoc(doc(db, "users", uid), {
            state: state
        })
        setConfirmState(true);
    }

    const auth = getAuth()
        auth.onAuthStateChanged((user) => {
            if (!user) {
                navigate('/login');
            }})

    return(
        <div>
            <div className='navbar-container'>
            <div className='menu-list-container'>
                <img className='other-logo' src={logo}/>
                <ul className='navbar-menu-list'>
                </ul>
            </div>
        </div>
        <div className='onboarding-container'>
            <h1 className='settings-header'>Step 1: Confirm Your State</h1>
            <p className='settings-text'>If there is an instance in which there is <strong>no defining location information</strong> for a contact in an uploaded CSV, we will first try to determine their location using their phone number. If there is no phone number, we will use your state to try to pull donation history for uploaded contacts. Your default state can be updated at any time in <strong>Settings.</strong><br/><br/> We require at least a First and Last name to upload a file to RaiseSmart AI. Accepted optional fields include: Phone Number, Address, City, State, and Zipcode. <strong>The more fields you provide, the better your results!</strong></p>
            <div className='fec-input-container'>
                <div>
            <select className='state-select' onChange={e => getOptionSelect(e.target.value)}>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District Of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
        </select>
        <div className='fec-buttons'>
                    <CustomButton  buttonStyle='teal-button' buttonLabel='Save State' onClick={() => saveState()} />
                    </div>
        </div>
        <div>
                    {confirmState && <p className='fec-success'>State Updated</p>}
                </div>
                </div>
        <h1 className='settings-header'>Step 2: Choose Your Plan</h1>
        <p className='settings-text'>You will have to purchase credits below in order to use RaiseSmart AI. <strong>One credit is equal to one attempt at collecting Federal and State donation history for a single contact. To find Phone Number and/or Email Address, each successful match will cost two (2) credits.</strong></p>
        <Products />
        <div className='free-contacts'>
        <h2 className='settings-header-free'>We have given you 50 free contacts as a thank you for trying RaiseSmart AI!</h2>
        <CustomButton buttonStyle='teal-button' buttonLabel='Next' onClick={() => navigate('/')} />
        </div>
        </div>
        </div>
    )
}

export default Onboarding;