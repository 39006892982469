import React, {useState} from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {  createUserWithEmailAndPassword  } from 'firebase/auth';
import CustomButton from './Button';
import { auth } from '../firebase';
import { db } from '../firebase';
import { doc, setDoc, getDoc } from "firebase/firestore";
import GoogleButton from 'react-google-button'
import { GoogleAuthProvider, signInWithPopup, sendEmailVerification } from "firebase/auth";
import logo from './images/RaiseSmart AI.png'
 
const SignUp = () => {
    const navigate = useNavigate();
    const isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;
 
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');
    const [confirmedPassword, setConfirmedPassword] = useState('');
    const [confirmedPasswordError, setConfirmedPasswordError] = useState(false)

    const confirmPassword = (p) => {
        if (p != password) {
            setConfirmedPasswordError(true);
        }
        else {
            setConfirmedPassword(p);
            setConfirmedPasswordError(false);
        }
    }

    const provider = new GoogleAuthProvider();

    const googleLogin = () => {
        signInWithPopup(auth, provider)
        .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            // The signed-in user info.
            const user = result.user;
            console.log(user.uid);
            getDoc(doc(db, "users", user.uid)).then(docSnap => {
                const userDoc = docSnap.exists();
                console.log(userDoc);
                if(userDoc) {
                    navigate("/");
                    console.log(user);
                }
                else{
                    const creationDate = new Date()
                    setDoc (doc(db, "users", user.uid), {
                        email: user.email,
                        creationDate: creationDate,
                        contactsRemaining: 50,
                        contactsRemainingBeforeImport: 50,
                        totalContactsPurchased: 0,
                        fecKey: "",
                        state: "",
                        importInProgress: false
                    });
                    navigate('/onboarding')
                }
            })
            // IdP data available using getAdditionalUserInfo(result)
            // ...
        }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            alert(errorMessage);
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            // ...
        });
    }
 
    const onSubmit = async (e) => {
        console.log(email);
        if (confirmedPassword == password) {
            console.log('Signing Up')
            e.preventDefault()
     
      await createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            const uid = user.uid;
            const email = user.email;
            const creationDate = new Date()
            setDoc (doc(db, "users", uid), {
                email: email,
                creationDate: creationDate,
                contactsRemaining: 50,
                contactsRemainingBeforeImport: 50,
                totalContactsPurchased: 0,
                fecKey: "",
                state: "",
                importInProgress: false
            });
            sendEmailVerification(auth.currentUser).then(() => {
                navigate("/verify");
            })
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
            // ..
        });
        }
        else {
            if (confirmedPassword != password){
                alert('Passwords do not match, please try again.')
            }
            if (email == ''){
                alert('Please enter email.')
            }
            if (confirmedPassword == ''){
                alert('Please confirm your password.')
            }
        }
   
    }
    if (isMobile){
        return(
            <div>Sorry, RaiseSmart AI is only available on Desktop. Please access the app from a computer.</div>
        )
    }

    else{
        return (
            <>
                    <main >        
                        <section className='login-columns'>
                            <div className='login-container'>                                                                  
                                <div className='login-form-container'>
                                <img className='login-logo' src={logo}/>
                                    <div className='login-form'>                    
                                <form>                                              
                                    <div className='login-input-container'>
                                        <label htmlFor="email-address" className='login-input-label'>
                                            Email address
                                        </label>
                                        <input
                                            id="email-address"
                                            name="email"
                                            type="email"                                    
                                            required                                                                                
                                            placeholder="Email address"
                                            className='login-input-field'
                                            onChange={(e)=>setEmail(e.target.value)}
                                        />
                                    </div>
        
                                    <div className='login-input-container'>
                                        <label htmlFor="password" className='login-input-label'>
                                            Password
                                        </label>
                                        <input
                                            id="password"
                                            name="password"
                                            type="password"                                    
                                            required                                                                                
                                            placeholder="Password"
                                            className='login-input-field'
                                            onChange={(e)=>setPassword(e.target.value)}
                                        />
                                    </div>
        
                                    <div className='login-input-container'>
                                        <label htmlFor="password" className='login-input-label'>
                                            Confirm Password
                                        </label>
                                        <input
                                            id="confirm-password"
                                            name="confirm-password"
                                            type="password"                                    
                                            required                                                                                
                                            placeholder="Confrim Password"
                                            className='login-input-field'
                                            onChange={(e)=>confirmPassword(e.target.value)}
                                        />
                                        {confirmedPasswordError && <p className='confirm-password-error'>Your passwords do not match.</p>}
                                    </div>
                                                        
                                    <div>
                                        <CustomButton                                    
                                            onClick={onSubmit}
                                            buttonStyle='teal-button-navbar'
                                            buttonLabel='Sign Up'                                        
                                        >                                                                       
                                        </CustomButton>
                                    </div>                               
                                </form>
                                <p className='disclaimer-text'>By creating an account, you are adhering to our <a className='disclaimer-navlink' href='https://raisesmartai.com/terms-of-service' target='_blank'>Terms of Service</a>.</p>
                               
                                <p className="signup-text">
                                    Already have an account? {' '}
                                    <NavLink className='signup-navlink' to="/login">
                                        Login
                                    </NavLink>
                                </p>
                                <div className='google-signin-container'>
                                    <h2>
                                        Or
                                    </h2>
                            <GoogleButton
                                onClick={() => googleLogin()}
                            />
                            </div>
                                </div>
                                </div>                          
                            </div>
                        </section>
                    </main>
                </>
          )
    }
 
}
 
export default SignUp