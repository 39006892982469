import React, {useState} from 'react';
import {  signInWithEmailAndPassword   } from 'firebase/auth';
import { auth } from '../firebase';
import { db } from '../firebase';
import { NavLink, useNavigate } from 'react-router-dom'
import CustomButton from './Button';
import { GoogleAuthProvider, signInWithPopup, sendEmailVerification } from "firebase/auth";
import GoogleButton from 'react-google-button'
import { doc, setDoc, getDoc } from "firebase/firestore";
import logo from './images/RaiseSmart AI.png';

const provider = new GoogleAuthProvider();
 
const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [errorTrue, setErrorTrue] = useState(false)
    const isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;
       
    const onLogin = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            navigate("/");
            setErrorTrue(false);
            console.log(user);
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            setErrorTrue(true);
            setError(errorMessage);
            console.log(errorCode, errorMessage)
        });
       
    }

    const googleLogin = () => {
        signInWithPopup(auth, provider)
        .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            // The signed-in user info.
            const user = result.user;
            console.log(user.uid);
            getDoc(doc(db, "users", user.uid)).then(docSnap => {
                const userDoc = docSnap.exists();
                console.log(userDoc);
                if(userDoc) {
                    navigate("/");
                    console.log(user);
                }
                else{
                    const creationDate = new Date()
                    setDoc (doc(db, "users", user.uid), {
                        email: user.email,
                        creationDate: creationDate,
                        contactsRemaining: 50,
                        contactsRemainingBeforeImport: 50,
                        totalContactsPurchased: 0,
                        fecKey: "",
                        state: "",
                        importInProgress: false
                    });
                    navigate('/onboarding');
                }
            })
            // IdP data available using getAdditionalUserInfo(result)
            // ...
        }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            alert(errorMessage);
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            // ...
        });
    }

    if(isMobile) {
        return(
            <div>Sorry, RaiseSmart AI is only available on Desktop. Please access the app from a computer.</div>
        )
    }

    else{
        return(
            <>
                <main>        
                    <section className='login-columns'>
                        <div className='login-container'>                                                                  
                            <div className='login-form-container'>
                            <img className='login-logo' src={logo}/> 
                                <div className='login-form'>                   
                            <form>                                              
                                <div className='login-input-container'>
                                    <label htmlFor="email-address" className='login-input-label'>
                                        Email address
                                    </label>
                                    <input
                                        id="email-address"
                                        name="email"
                                        type="email"                                    
                                        required                                                                                
                                        placeholder="Email address"
                                        className='login-input-field'
                                        onChange={(e)=>setEmail(e.target.value)}
                                    />
                                </div>
    
                                <div className='login-input-container'>
                                    <label htmlFor="password" className='login-input-label'>
                                        Password
                                    </label>
                                    <input
                                        id="password"
                                        name="password"
                                        type="password"                                    
                                        required                                                                                
                                        placeholder="Password"
                                        className='login-input-field'
                                        onChange={(e)=>setPassword(e.target.value)}
                                    />
                                    {errorTrue && <p className='confirm-password-error'>{error}</p>}
                                </div>
                                                    
                                <div>
                                    <CustomButton                                    
                                        onClick={onLogin}
                                        buttonStyle='teal-button-navbar'
                                        buttonLabel='Login'                                        
                                    >                                                                       
                                    </CustomButton>
                                </div>                               
                            </form>
                           
                            <p className="signup-text">
                                No account yet? {' '}
                                <NavLink className='signup-navlink' to="/signup">
                                    Sign up
                                </NavLink>
                            </p>
                            <div className='google-signin-container'>
                                <h2>
                                    Or
                                </h2>
                        <GoogleButton
                            onClick={() => googleLogin()}
                        />
                        </div>
                            </div>
                            </div>                          
                        </div>
                    </section>
                </main>
            </>
        )
    }
}
 
export default Login