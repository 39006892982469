import React, {useState, useEffect} from 'react';
import Papa from 'papaparse';
import '../App.css'
import {getAuth, onAuthStateChanged} from 'firebase/auth';
import { db, stripe } from '../firebase';
import { doc, getDoc, setDoc, updateDoc, addDoc, collection } from "firebase/firestore";
import { NavLink, useNavigate } from 'react-router-dom';
import NavBar from './NavBar'
import CustomButton from './Button';
import ContactsRemainingComp from './ContactsRemaining';
import { Dropbox, setRefreshToken } from 'dropbox';

const Home = () => {
    // State to store parsed data
  const [parsedData, setParsedData] = useState([]);

  //State to store table Column name
  const [tableRows, setTableRows] = useState([]);

  //State to store the values
  const [values, setValues] = useState([]);

  const [pageState, setPageState] = useState(0);

  const goToNextPage = () => {
    if (userState == ""){
        alert('No Default State detected. Please go to Settings and input your Default State.');
    }
    if (firstNameSelect == -1){
        alert('First Name column is not mapped. Please map the First Name column.')
    }
    if (lastNameSelect == -1){
        alert('Last Name column is not mapped. Please map the Last Name column.')
    }
    if (lastNameSelect != -1 && firstNameSelect != -1 && userState != "") {
        if (emailSelect == -1 || phoneSelect == -1) {
            setTotalCredits(values.length * 2)
        }
        else{
            let creditCounter = 0
                values.map((value) => {
                    console.log(value)
                    if(value[phoneSelect] == "" || value[emailSelect] == ""){
                        creditCounter = creditCounter + 2
                    }
                    else{
                        creditCounter = creditCounter + 1
                    }
                })
            setTotalCredits(creditCounter)
        }
        setPageState(1);
    }
  }

  const [contactLimit, setContactLimit] = useState([]);

  const [contactsRemaining, setContactsRemaining] = useState(0);
  const [email, setEmail] = useState("");

  const [firstNameSelect, setFirstNameSelect] = useState(-1);
  const [lastNameSelect, setLastNameSelect] = useState(-1);
  const [phoneSelect, setPhoneSelect] = useState(-1);
  const [addressSelect, setAddressSelect] = useState(-1);
  const [citySelect, setCitySelect] = useState(-1);
  const [stateSelect, setStateSelect] = useState(-1);
  const [zipcodeSelect, setZipcodeSelect] = useState(-1);
  const [emailSelect, setEmailSelect] = useState(-1);
  const [uploadedFile, setUploadedFile] = useState([]);
  const [showButton, setShowButton] = useState(false);
  const [showUpload, setShowUpload] = useState(true);
  const [fileName, setFileName] = useState("");
  const [userState, setUserState] = useState('');
  const [selectorState, setSelectorState] = useState(true);
  const [importProgress, setImportProgress] = useState(false);
  const [totalCredits, setTotalCredits] = useState(0);

  const auth = getAuth();
  const navigate = useNavigate();
  

  const getOptionSelect = (selectValue, index) => {
    if (selectValue == "First Name") {
        setFirstNameSelect(index);
    }
    if (selectValue == 'Last Name') {
        setLastNameSelect(index);
    }
    if (selectValue == 'Phone Number') {
        setPhoneSelect(index);
    }
    if (selectValue == "Address") {
        setAddressSelect(index);
    }
    if (selectValue == "City") {
        setCitySelect(index);
    }
    if (selectValue == "State") {
        setStateSelect(index);
    }
    if (selectValue == "Zipcode") {
        setZipcodeSelect(index);
    }
    if (selectValue == "Email") {
        setEmailSelect(index);
    }
  }

  const getCurrentUserMonthlyRemaining = () => {
    const user = auth.currentUser
    const uid = user.uid;
    getDoc(doc(db, "users", uid)).then(docSnap => {
        setContactsRemaining(docSnap.data().contactsRemaining);
        setUserState(docSnap.data().state);
        setEmail(docSnap.data().email);
        setImportProgress(docSnap.data().importInProgress);
    })


}

    const deleteFile = () => {
        setShowButton(false);
        setShowUpload(true);
        setParsedData([]);
        setTableRows([]);
        setValues([]);
        setFirstNameSelect(-1);
        setLastNameSelect(-1);
        setPhoneSelect(-1);
        setAddressSelect(-1);
        setCitySelect(-1);
        setStateSelect(-1);
        setZipcodeSelect(-1);
        setEmailSelect(-1);
    }

    const goBack = () => {
        setFirstNameSelect(-1);
        setLastNameSelect(-1);
        setPhoneSelect(-1);
        setAddressSelect(-1);
        setCitySelect(-1);
        setStateSelect(-1);
        setZipcodeSelect(-1);
        setEmailSelect(-1);
        setPageState(0);
    }

    const changeHandler = (event) => {
        setShowButton(true);
        setShowUpload(false);
        setFileName(event.target.files[0].name);
        setUploadedFile(event.target.files[0]);
            Papa.parse(event.target.files[0], {
                header: true,
                skipEmptyLines: true,
                complete: function (results) {
                const rowsArray = [];
                const valuesArray = [];

                results.data.map((d) => {
                    rowsArray.push(Object.keys(d));
                    valuesArray.push(Object.values(d));
                });

                setParsedData(results.data);
                setTableRows(rowsArray[0]);
                setValues(valuesArray);
                const vals = valuesArray
                if(vals.length >= 5){
                    setSelectorState(false);
                }
                else{
                    setSelectorState(true);
                }
                },
            });
    }

    const uploadFileToAPI = async () => {
        const file = uploadedFile;
        const fname = fileName + '-' + Date.now().toString();
        const config = {
            clientId: process.env.REACT_APP_DROPBOX_APP_KEY,
            refreshToken: process.env.REACT_APP_DROPBOX_REFRESH_TOKEN,
            clientSecret: process.env.REACT_APP_DROPBOX_APP_SECRET

          };
        const dbx = new Dropbox(config);
        if (file != null) {
            const filePath = '/' + auth.currentUser.uid + '/' + fname + '.csv';
            dbx.filesUpload({path: filePath, contents: file})
            .then(function(response){
                console.log(response);
            }).catch(function(error){
                alert(error);
            })
            const newContactsRemaining = contactsRemaining-totalCredits
            const userData = JSON.stringify(
                {'mapping' : [firstNameSelect,
                lastNameSelect,
                phoneSelect,
                addressSelect,
                citySelect,
                stateSelect,
                zipcodeSelect,
                emailSelect],
                'uid': auth.currentUser.uid,
                'filePath': filePath,
                'fileName': fname,
                'oldFileName': fileName,
                'state': userState,
                'email': email,
                'newContactsRemaining': newContactsRemaining}
                )
                fetch('https://givinghistoryscraperapi.onrender.com/api/handleUpload',
                {
                    method: 'post',
                    body: userData,
                    headers: { "content-type": "application/json"},
                })
                navigate('/upload-complete');
            }
        }

        auth.onAuthStateChanged((user) => {
            if (!user) {
                navigate('/login');
            }
            if(user.emailVerified != null){
                if(!user.emailVerified){
                    navigate('/verify')
                }
                else {
                    getCurrentUserMonthlyRemaining();
                }
            } else {
                getCurrentUserMonthlyRemaining();
            }
        })

    while (pageState == 0){
        return (
            <div className='home-screen'>
                <NavBar pageMiddle='Buy Credits' pathMiddle='/buy-contacts' pageFirst='Settings' pathFirst='/settings' loggedIn={true}/>
                <div>
                    <div className='upload-map-container'>
                    {showUpload && <div className='upload-input'>
                        <h1>Upload your file here</h1>
                        <input type={"file"} accept={".csv"} onChange={changeHandler}/>
                        </div>}
                    {showButton && <div className='upload-container'>
                        <div className='file-meta'>
                        <h1>{fileName}</h1>
                        </div>
                        <div className='file-meta-button-row'>
                        <div className='file-meta-button'>
                        <CustomButton buttonStyle='red-button' buttonLabel='Delete' onClick={() => deleteFile()} />
                        </div>
                            {!importProgress && <div className='mapping-next'><CustomButton buttonStyle='teal-button' buttonLabel='Next' onClick={() => goToNextPage()}>Next</CustomButton></div>}
                            {importProgress && <div className='mapping-next'><CustomButton buttonStyle='disabled-button' buttonLabel='Next'>Next</CustomButton>
                            <p>Import in progress...</p></div>}
                        </div>
                        </div>}
                        <ContactsRemainingComp contactsRemaining={contactsRemaining} settings={false} />
                        </div>
                <br />
        <br />
        {/* Table */}
        <div className='map-container'>
        <div className='map-table'>
        { showButton && <h2>Please map the fields of your CSV below.</h2>}
            {tableRows.map((row, columnIndex) => (
                    <table key={row}>
                        <thead className='map-head'>
                            <tr>
                                <th>{row}</th>
                            </tr>
                        </thead>
                        <tbody>
                        {values.map((value, index) => {
                if (values.length > 5) {
                    while (index < 6) {
                        if (index != 5) {
                            return <tr className='map-row' key={index}><td className='map-cell' key={index}>{value[columnIndex]}</td></tr>;
                        }
                        else {
                        return <tr key={index}><td align='center'>
                            <select className='map-select' onChange={ e => getOptionSelect(e.target.value, columnIndex)}>
                                <option value="Ignored">Select...</option>
                                <option value="First Name">First Name</option>
                                <option value="Last Name">Last Name</option>
                                <option value="Phone Number">Phone Number</option>
                                <option value="Address">Address</option>
                                <option value="City">City</option>
                                <option value="State">State</option>
                                <option value="Zipcode">Zipcode</option>
                                <option value="Email">Email Address</option>
                                <option value="Ignored">Ignore Column</option>
                            </select></td></tr>;
                        }
            }}
            else {
                    if (index != values.length){
                        return(
                            <tr className='map-row' key={index}><td className='map-cell' key={index}>{value[columnIndex]}</td></tr>
                        )
                    }
    }})}
                        {selectorState && <tr key={values.length}><td align='center'>
                            <select className='map-select' onChange={ e => getOptionSelect(e.target.value, columnIndex)}>
                                <option value="Ignored">Select...</option>
                                <option value="First Name">First Name</option>
                                <option value="Last Name">Last Name</option>
                                <option value="Phone Number">Phone Number</option>
                                <option value="Address">Address</option>
                                <option value="City">City</option>
                                <option value="State">State</option>
                                <option value="Zipcode">Zipcode</option>
                                <option value="Email">Email Address</option>
                                <option value="Ignored">Ignore Column</option>
                            </select></td></tr>}
                        </tbody>
                    </table>
            ))}
            </div>
        </div>
            </div>
            </div>
        );
    }
    if (totalCredits <= contactsRemaining){
        return (
            <div className='home-screen'>
                <NavBar pageMiddle='Buy Credits' pathMiddle='/buy-contacts' pageFirst='Settings' pathFirst='/settings' loggedIn={true}/>
                <div className='confirm-screen'>
                <h1>You are using {totalCredits} credits to import {values.length} contacts.</h1>
                <h2>You will receive an email with your new CSV once your import is complete.</h2>
                <p>The number above could include empty rows in your CSV. We will remove empty rows during the import process and you will not have credits deducted from your account because of empty rows. <strong>If we can not find a matching Phone Number or Email for a contact, you will only be charged one credit for that contact.</strong> Your account's credit total will update upon import completion.</p>
                <div className='import-confirmation-page'>
                    <CustomButton buttonStyle='red-button' buttonLabel='Back' onClick={() => goBack()} />
                    <CustomButton buttonStyle='teal-button' buttonLabel='Import' onClick={() => uploadFileToAPI()}/>
                </div>
                </div>
            </div>
    )}

    if(totalCredits > contactsRemaining){
        return (
            <div className='home-screen'>
                <NavBar pageMiddle='Buy Credits' pathMiddle='/buy-contacts' pageFirst='Settings' pathFirst='/settings' loggedIn={true}/>
                <div className='confirm-screen'>
                <h1>You do not have the {totalCredits} credits required for this import of {values.length} contacts.</h1>
                <h2 className='no-import-warning'>You only have {contactsRemaining} credits left. Please upload a new CSV with less contacts, or purchase more credits to continue.</h2>
                <p>This might be caused by empty rows in your CSV. We recommend removing empty rows before you upload your CSV.</p>
                <div className='import-confirmation-page'>
                <CustomButton buttonLabel='Back' buttonStyle='red-button' onClick={() => goBack()} />
                <CustomButton buttonLabel='Buy Contacts' buttonStyle='teal-button' onClick={() => navigate('/buy-contacts')}/>
                </div>
                </div>
            </div>
    )}}

export default Home;
