import React from 'react'
import {getAuth, onAuthStateChanged, sendEmailVerification} from 'firebase/auth';
import CustomButton from './Button';
import { NavLink, useNavigate } from 'react-router-dom';
import NavBar from './NavBar';
import logo from './images/RaiseSmart AI.png'

const Verify = () => {

    const auth = getAuth()
    const navigate = useNavigate()

    auth.onAuthStateChanged(function (user) {
        if (!user) {
            navigate('/login')
        }
        else{
            if (user.emailVerified == null){
                navigate('/onboarding')
            }
            if (user.emailVerified){
                navigate('/onboarding')
            }
        }
    })
    return(
        <div>
            <div className='navbar-container'>
            <div className='menu-list-container'>
                <img className='other-logo' src={logo}/>
                <ul className='navbar-menu-list'>
                </ul>
            </div>
        </div>
        <div className='onboarding-container'>
            <h1 className='settings-header'>Verify your Email</h1>
            <p className='settings-text'>Please check your email to verify your account. If you did not receive an email, click the button below to send a new one. If you have verified your email, refresh the page.</p>
            <div className='settings-header'>
            <CustomButton buttonLabel='Send Email' buttonStyle='red-button' onClick={() => sendEmailVerification(auth.currentUser)} />
            </div>
        </div>
        </div>
    )
}

export default Verify;