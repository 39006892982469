import { BrowserRouter, Route, Routes, Switch } from 'react-router-dom'
import Login from './components/Login'
import SignUp from './components/signUp'
import Settings from './components/Settings'
import Home from './components/Home'
import useToken from './components/useToken'
import './App.css'
import Verify from './components/Verify'
import Onboarding from './components/Onboarding'
import UploadComplete from './components/UploadComplete'
import BuyContacts from './components/BuyContacts'

function App() {
  return (
    <BrowserRouter>
      <Routes>
          <Route path = "/login" element = { <Login></Login> }></Route>
          <Route path = "/signup" element = { <SignUp></SignUp> } ></Route>
          <Route path = "/" element ={<Home></Home>} ></Route>
          <Route path='/settings' element = {<Settings></Settings>} ></Route>
          <Route path='/verify' element = {<Verify></Verify>} ></Route>
          <Route path='/onboarding' element = {<Onboarding></Onboarding>}></Route>
          <Route path='/upload-complete' element = {<UploadComplete></UploadComplete>}></Route>
          <Route path='/buy-contacts' element = {<BuyContacts></BuyContacts>}></Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App;
