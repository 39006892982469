import React from 'react';
import NavBar from './NavBar';
import { useNavigate } from 'react-router-dom';
import CustomButton from './Button';

const UploadComplete = () => {

    const navigate = useNavigate();

    return (
        <div>
            <NavBar pageMiddle='Buy Contacts' pathMiddle='/buy-contacts' pageFirst='Settings' pathFirst='/settings' loggedIn={true}/>
            <div className='import-path-container'>
                <h1>We are uploading your contacts now.</h1>
                <br />
                <p>Expect an email soon with your new CSV. Please note, larger CSVs take longer to import - if you do not receive an email from us within 6 hours, please email support@raisesmartai.com for further assistance.</p>
                <br />
                <CustomButton buttonStyle='teal-button' buttonLabel='Import More' onClick={() => navigate('/')} />
            </div>
        </div>
    )
}

export default UploadComplete;