import React from 'react';

const CustomButton = (props) => {
    return(
        <div className={props.buttonStyle} onClick={props.onClick}>
            <p>{props.buttonLabel}</p>
        </div>
    )
}

export default CustomButton;