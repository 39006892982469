import {Reac,useState} from 'react'
import {getAuth, onAuthStateChanged} from 'firebase/auth';
import { db } from '../firebase';
import { doc, getDoc, setDoc, updateDoc, addDoc, collection, onSnapshot } from "firebase/firestore";
import CustomButton from './Button';

const Products = () => {

    const [loading1, setLoading1] = useState('Purchase');
    const [loading2, setLoading2] = useState('Purchase');
    const [loading3, setLoading3] = useState('Purchase');
    const [loading4, setLoading4] = useState('Purchase');

    const getCheckOut = async  (num) => {
        let doc_id = ""
        let price = ""
        if (num == 200){
            price = process.env.REACT_APP_200_PRICE_ID
            setLoading1('Loading...')
        }
        if (num == 1000){
            price = process.env.REACT_APP_1000_PRICE_ID
            setLoading2('Loading...')
        }
        if (num == 4000){
            price = process.env.REACT_APP_4000_PRICE_ID
            setLoading3('Loading...')
        }
        if (num == 10000){
            price = process.env.REACT_APP_10000_PRICE_ID
            setLoading4('Loading...')
        }
        const auth = getAuth();
        addDoc(collection(db, 'users', auth.currentUser.uid, 'checkout_sessions'),
        {
            mode: 'payment',
            price: price,
            allow_promotion_codes: true,
            metadata: {
                "numOfContacts": num
            },
            success_url: window.location.origin,
            cancel_url: window.location.origin,
        }).then(docSnap => {
            doc_id = docSnap._key.path.segments[3];
            setTimeout(() => {
                getDoc(doc(db, 'users', auth.currentUser.uid, 'checkout_sessions', doc_id)).then(docSnap => {
                    console.log(docSnap.data());
                    const {error, url} = docSnap.data();
                    if (error) {
                        // Show an error to your customer and
                        // inspect your Cloud Function logs in the Firebase console.
                        alert(`An error occured: ${error.message}`);
                    }
                    if (url) {
                        // We have a Stripe Checkout URL, let's redirect.
                        window.location.assign(url);
                    }
                    setLoading1('Purchase')
                    setLoading2('Purchase')
                    setLoading3('Purchase')
                    setLoading4('Purchase')
            })},4000)
        });
        }

    return (
        <div className='products'>
            <div className='product-list-container'>
                <div className='products-container'>
                    <div className='product-item'>
                        <div className='product-meta'>
                        <h2>200 Credits</h2>
                        <p>$50 at <strong>$0.25/credit</strong></p>
                        <h4>One credit gives you the federal and state donation history of a contact, while two credits gives you a phone number, email, and federal/state donation history of a contact. Purchase 200 credits at $0.25 per credit.</h4>
                        </div>
                        <div className='product-purchase-button'>
                        <CustomButton buttonStyle='teal-button-navbar' buttonLabel={loading1} onClick={() => getCheckOut(200)} />
                        </div>
                    </div>
                    <div className='product-item'>
                        <div className='product-meta'>
                        <h2>1,000 Credits</h2>
                        <p>$200 at <strong>$0.20/credit</strong></p>
                        <h4>One credit gives you the federal and state donation history of a contact, while two credits gives you a phone number, email, and federal/state donation history of a contact. Purchase 1,000 credits at $0.20 per credit.</h4>
                        </div>
                        <div className='product-purchase-button'>
                        <CustomButton buttonStyle='teal-button-navbar' buttonLabel={loading2} onClick={() => getCheckOut(1000)} />
                        </div>
                    </div>
                    <div className='product-item'>
                        <div className='product-meta'>
                        <h2>4,000 Credits</h2>
                        <p>$600 at <strong>$0.15/credit</strong></p>
                        <h4>One credit gives you the federal and state donation history of a contact, while two credits gives you a phone number, email, and federal/state donation history of a contact. Purchase 4,000 credits at $0.15 per credit.</h4>
                        </div>
                        <div className='product-purchase-button'>
                        <CustomButton buttonStyle='teal-button-navbar' buttonLabel={loading3} onClick={() => getCheckOut(4000)} />
                        </div>
                    </div>
                    <div className='product-item'>
                        <div className='product-meta'>
                        <h2>10,000 Credits</h2>
                        <p>$1,000 at <strong>$0.10/credit</strong></p>
                        <h4>One credit gives you the federal and state donation history of a contact, while two credits gives you a phone number, email, and federal/state donation history of a contact. Purchase 10,000 credits at $0.10 per credit.</h4>
                        </div>
                        <div className='product-purchase-button'>
                        <CustomButton buttonStyle='teal-button-navbar' buttonLabel={loading4} onClick={() => getCheckOut(10000)} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Products;