import React from 'react';
import CustomButton from './Button';
import {getAuth, signOut} from 'firebase/auth';
import { NavLink, useNavigate } from 'react-router-dom'
import logo from './images/RaiseSmart AI.png'

const NavBar = (props) => {

    const navigate = useNavigate()

    const logOutButton = () => {
        const auth = getAuth();
        signOut(auth);
    }

    return(
        <div className='navbar-container'>
            <div className='menu-list-container'>
                <img className='nav-logo' src={logo} onClick={() => navigate('/')}/>
                <ul className='navbar-menu-list'>
                    <a href='/login'>
                        <li className='navbar-menu-item'><CustomButton onClick={() => logOutButton()}buttonStyle='red-button-navbar' buttonLabel='Logout'/></li>
                    </a>
                    <li className='navbar-menu-item'><CustomButton buttonStyle='teal-button-navbar' buttonLabel={props.pageMiddle} onClick={() => navigate(props.pathMiddle)}/></li>
                    <li className='navbar-menu-item'><CustomButton buttonStyle='teal-button-navbar' buttonLabel={props.pageFirst} onClick={() => navigate(props.pathFirst)}/></li>
                </ul>
            </div>
        </div>
    )
}

export default NavBar;